<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="id_pos"
        :items="poss"
        label="Chọn pos*"
        @change="focusBasketInput"
        dense
        outlined
        clearable
        hide-details
        class="c-input-small"
      ></v-autocomplete>
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model.number="basket_code"
        ref="basketCodeRef"
        class="c-input-small"
        :label="$t('labels.scan_basket_code')"
        dense
        outlined
        clearable
        hide-details
        :disabled="!id_pos"
        @keyup.enter="getReturnBasket"
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('basket_code')"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-btn
        color="success"
        block
        :disabled="basket_codes.length === 0"
        @click="createCommand"
        >{{ $t("labels.confirm") }}</v-btn
      >
    </v-col>

    <v-col cols="12" v-if="basketReverse.length > 0">
      {{ $t("labels.scanned") }}:
      <v-chip
        class="ma-2"
        color="primary"
        v-for="(bc, i) in basketReverse"
        :key="`bc_${bc}`"
        >{{ bc }} ({{ basketReverse.length - i }})</v-chip
      >
    </v-col>

    <v-col cols="12" v-if="waiting_baskets.length > 0">
      <v-simple-table fixed-header height="calc(100vh - 145px)">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.basket") }}
                <span class="error--text">({{ countWaiting }})</span>
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.status") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.position") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.employee") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in waiting_baskets"
              :key="item.id"
              class="text-center"
            >
              <td>{{ item.basket_code }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>
                {{
                  item.status === 1
                    ? $t("labels.waiting_stowing")
                    : $t("labels.process_stowing")
                }}
              </td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.cell_id }}</td>
              <td>{{ item.employee_process_name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "GoodsReturnReceiveBasket",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  data: () => ({
    basket_code: null,
    basket_codes: [],
    isLoading: false,
    poss: [],
    id_pos: null,
    waiting_baskets: [],
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    basketReverse() {
      if (!this.basket_codes || this.basket_codes.length === 0) {
        return [];
      }
      return [...this.basket_codes].reverse();
    },
    countWaiting() {
      if (!this.waiting_baskets || this.waiting_baskets.length === 0) {
        return 0;
      }
      const wt = this.waiting_baskets.filter((w) => w.status === 1);
      return wt.length;
    },
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.getReturnBasket();
    },
    async getReturnBasket() {
      if (!this.basket_code) {
        return false;
      }

      const checkBasket = this.basket_codes.find((c) => c == this.basket_code);
      if (checkBasket) {
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(
          this.$t("messages.scanned_basket_code", { code: this.basket_code })
        );
        this.basket_code = null;
        return false;
      }

      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/order-online-return-stowing-get-basket-waiting-stowing",
          {
            basket_code: this.basket_code,
            id_pos: this.id_pos,
          }
        );
        this.basket_codes.push(data.basket_code);
        this.isLoading = false;
        this.basket_code = null;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
      }
    },
    async getposs() {
      const { poss } = window.me;
      this.poss = [...poss].map((p) => ({
        value: p.id,
        text: p.code || "",
      }));
    },
    focusBasketInput() {
      this.waiting_baskets = [];
      if (!this.id_pos) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.basketCodeRef.focus();
        this.getReturnBasketWaiting();
      });
    },
    async createCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/order-online-return-stowing-create-command",
          {
            basket_codes: this.basket_codes,
            id_pos: this.id_pos,
          }
        );
        this.$emit("submit", data);
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
      }
    },
    async getReturnBasketWaiting() {
      const { data } = await httpClient.post(
        "/order-online-return-stowing-list-basket-waiting",
        {
          id_pos: this.id_pos,
        }
      );
      this.waiting_baskets = [...data];
    },
  },
  mounted() {
    this.getposs();
  },
};
</script>

<style scoped></style>
